// extracted by mini-css-extract-plugin
export var centerAlign = "IconBox__centerAlign__YoEhS";
export var column = "IconBox__column__Uv6iN";
export var description = "IconBox__description__YfHY4";
export var flex = "IconBox__flex__EQEFU";
export var flexColumn = "IconBox__flexColumn__VGUyj";
export var gap1 = "IconBox__gap1__yPLae";
export var gap2 = "IconBox__gap2__L_WB5";
export var gap3 = "IconBox__gap3__Uirhz";
export var gap4 = "IconBox__gap4__TSwhJ";
export var gap5 = "IconBox__gap5__V4CyJ";
export var iconContainer = "IconBox__iconContainer__BggdZ";
export var leftAlign = "IconBox__leftAlign__pq3Ij";
export var link = "IconBox__link__ikEAh";
export var rightAlign = "IconBox__rightAlign__e6fsP";
export var row = "IconBox__row__YE17Y";
export var textContainer = "IconBox__textContainer__OH3rR";