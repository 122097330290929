// extracted by mini-css-extract-plugin
export var column = "Icon__column__XWFzs";
export var flex = "Icon__flex__TrC0k";
export var flexColumn = "Icon__flexColumn__Zye1M";
export var gap1 = "Icon__gap1__heVI8";
export var gap2 = "Icon__gap2__kQFFD";
export var gap3 = "Icon__gap3__zgM0l";
export var gap4 = "Icon__gap4__QWfea";
export var gap5 = "Icon__gap5__D6XU_";
export var icon = "Icon__icon__iiX1g";
export var marginLeft = "Icon__marginLeft__e8CDx";
export var marginRight = "Icon__marginRight__SwuUG";
export var row = "Icon__row__doLPA";
export var sizeLg = "Icon__sizeLg__QU4PN";
export var sizeMd = "Icon__sizeMd__rmnrX";
export var sizeSm = "Icon__sizeSm__niaoi";
export var sizeXl = "Icon__sizeXl__kSPyU";
export var sizeXs = "Icon__sizeXs__WEL1E";
export var sizeXxl = "Icon__sizeXxl__MK_6P";
export var sizeXxs = "Icon__sizeXxs__dXytg";
export var sizeXxxl = "Icon__sizeXxxl__VXmOv";
export var sizeXxxs = "Icon__sizeXxxs__iuIQn";
export var sizeXxxxl = "Icon__sizeXxxxl__RCKon";
export var sizeXxxxxl = "Icon__sizeXxxxxl__bFrGn";