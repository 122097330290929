// extracted by mini-css-extract-plugin
export var bitmap = "Partner__bitmap__AqDCQ";
export var column = "Partner__column__nL3Pr";
export var details = "Partner__details__Ga5yn";
export var disabled = "Partner__disabled__Nhb_C";
export var error = "Partner__error__qnXWN";
export var flex = "Partner__flex__tc_Ka";
export var flexColumn = "Partner__flexColumn__CMgvi";
export var gap1 = "Partner__gap1__c8BDL";
export var gap2 = "Partner__gap2__SfeBv";
export var gap3 = "Partner__gap3__FQcyP";
export var gap4 = "Partner__gap4__it24W";
export var gap5 = "Partner__gap5__GivUH";
export var grouped = "Partner__grouped__wDPlK";
export var icon = "Partner__icon__eQwo4";
export var input = "Partner__input__IO7Vh";
export var leftIcon = "Partner__leftIcon__XgOPw";
export var line = "Partner__line__z9YYH";
export var link = "Partner__link__vbuIb";
export var marginLeft = "Partner__marginLeft__cRgO0";
export var marginRight = "Partner__marginRight__ZXZbN";
export var partnerContainer = "Partner__partnerContainer__dKPTk";
export var rectangle = "Partner__rectangle__Rpdzj";
export var row = "Partner__row__kpPLV";
export var sizeLg = "Partner__sizeLg__cI8zG";
export var sizeMd = "Partner__sizeMd__JLP84";
export var sizeSm = "Partner__sizeSm__TqWOE";
export var sizeXl = "Partner__sizeXl__rqahW";
export var sizeXs = "Partner__sizeXs__h2P4u";
export var sizeXxl = "Partner__sizeXxl__CjlTe";
export var sizeXxs = "Partner__sizeXxs__dAXM5";
export var sizeXxxl = "Partner__sizeXxxl__EabJA";
export var sizeXxxs = "Partner__sizeXxxs__iT4F4";
export var sizeXxxxl = "Partner__sizeXxxxl__oLfUL";
export var sizeXxxxxl = "Partner__sizeXxxxxl__J1N8z";
export var spinner = "Partner__spinner__jFFwk";
export var subtitle = "Partner__subtitle__ZPbdL";
export var title = "Partner__title__Sqelu";
export var withLeftSideAddon = "Partner__withLeftSideAddon__ZPjxK";
export var withRightSideAddon = "Partner__withRightSideAddon__X470s";
export var wrapper = "Partner__wrapper__amoz0";