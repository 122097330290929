// extracted by mini-css-extract-plugin
export var aboutContainer = "BasicHero__aboutContainer__RyXTb";
export var aboutFormContainer = "BasicHero__aboutFormContainer__olJbw";
export var aboutTextContainer = "BasicHero__aboutTextContainer__X6Uz7";
export var arrow = "BasicHero__arrow__yQnqI";
export var basicButton = "BasicHero__basicButton__rsG2S";
export var basicHeroContainer = "BasicHero__basicHeroContainer__G2V9R";
export var blueOverlay = "BasicHero__blueOverlay__igcCp";
export var column = "BasicHero__column__XPbs0";
export var container = "BasicHero__container__cJRq7";
export var containerGutter = "BasicHero__containerGutter__INpg4";
export var disabled = "BasicHero__disabled__Byx0d";
export var error = "BasicHero__error__OUcd2";
export var field = "BasicHero__field__oFO63";
export var field2MdLg = "BasicHero__field2_md_lg__DWQlJ";
export var fieldGroup1MdLg = "BasicHero__fieldGroup1_md_lg__ucd_2";
export var fieldGroup2AMdLg = "BasicHero__fieldGroup2a_md_lg__jsvx4";
export var fieldGroup2BMdLg = "BasicHero__fieldGroup2b_md_lg__guExY";
export var fieldGroupMdLg = "BasicHero__fieldGroup_md_lg__UUnHB";
export var flex = "BasicHero__flex__LiA85";
export var flexColumn = "BasicHero__flexColumn__v2z0h";
export var form = "BasicHero__form__w70N_";
export var gap1 = "BasicHero__gap1__RtGoP";
export var gap2 = "BasicHero__gap2__cLKEX";
export var gap3 = "BasicHero__gap3__cv0cL";
export var gap4 = "BasicHero__gap4__o9oek";
export var gap5 = "BasicHero__gap5__lRtmA";
export var grouped = "BasicHero__grouped__BKTqB";
export var icon = "BasicHero__icon__DA5W8";
export var input = "BasicHero__input__pRI68";
export var leftIcon = "BasicHero__leftIcon__ADTIu";
export var marginButtom0 = "BasicHero__marginButtom0__G6fhD";
export var marginHorizontal0 = "BasicHero__marginHorizontal0__uKTbQ";
export var marginLeft = "BasicHero__marginLeft__s8stI";
export var marginRight = "BasicHero__marginRight__mvfT8";
export var marginTop0 = "BasicHero__marginTop0__Hcsmi";
export var noOverlay = "BasicHero__noOverlay__HzD2O";
export var paddingHorizontal0 = "BasicHero__paddingHorizontal0__fbj9d";
export var purpleOverlay = "BasicHero__purpleOverlay__MFDnW";
export var row = "BasicHero__row__cVuXB";
export var sizeLg = "BasicHero__sizeLg__r8iuD";
export var sizeMd = "BasicHero__sizeMd__OlSDK";
export var sizeSm = "BasicHero__sizeSm__bhb9S";
export var sizeXl = "BasicHero__sizeXl__VtHAs";
export var sizeXs = "BasicHero__sizeXs__XV77L";
export var sizeXxl = "BasicHero__sizeXxl__SrUDi";
export var sizeXxs = "BasicHero__sizeXxs__mn2M7";
export var sizeXxxl = "BasicHero__sizeXxxl__l7AFr";
export var sizeXxxs = "BasicHero__sizeXxxs__UsiyW";
export var sizeXxxxl = "BasicHero__sizeXxxxl__y02dC";
export var sizeXxxxxl = "BasicHero__sizeXxxxxl__bbZ_w";
export var spinner = "BasicHero__spinner__PGV0q";
export var tealOverlay = "BasicHero__tealOverlay__USRTf";
export var withLeftSideAddon = "BasicHero__withLeftSideAddon__oz7un";
export var withRightSideAddon = "BasicHero__withRightSideAddon__oAwxa";
export var wrapper = "BasicHero__wrapper__pnAzw";